table {
	border-collapse: collapse;
	/* table-layout: fixed; */
	table-layout: auto;
	width: 100%;
}

table,
td {
	border: 1px solid #333;
	font-weight: 700;
	padding: 15px;
	font-size: 0.89rem;
}

caption[class='title_iae_legal'] {
	border: 1px solid #333;
	border-bottom: initial;
	caption-side: top;
	text-align: center;
	color: #333;
	font-size: 2rem;
}

div > table {
	margin: 0 auto;
}

thead th:nth-child(1) {
	width: 30%;
}

thead th:nth-child(2) {
	width: 25%;
}

thead th:nth-child(3) {
	width: 22.5%;
}

thead th:nth-child(4) {
	width: 22.5%;
}

tbody {
	text-align: center;
	font-size: 0.8rem;
}

tfoot,
thead {
	background-color: #333;
	color: #fff;
}

td .td__bcolor,
th .thead__bcolor {
	border: 1px dashed blue;
	border-top: initial;
	padding: 10px 15px;
}

#cloud-container {
	margin: 244px 0 0;
}

.tableExample {
	margin-top: 50px;
	padding: 5px 5px 55px 5px;
}

.juridique__head--bordure {
	height: 30px;
	width: 120%;
	border: 15px solid #303;
	border-radius: 13px 13px 0 0;
	background-color: #303;
	display: flex;
	flex-wrap: wrap;
	padding-top: 20px;
	margin: -2px -2px 10px;
}

.juridique__head--bordure {
	height: 30px;
	width: 120%;
	border: 15px solid #303;
	border-radius: 13px 13px 0 0;
	background-color: #303;
	display: flex;
	flex-wrap: wrap;
	padding-top: 20px;
	margin: -2px -2px 10px;
}

.tab-juridique {
	margin: 50px 0px 90px;
	column-fill: balance-all;
}

.juridique-content {
	padding: 15px 15px 0px;
}

.thead__bcolor {
	color: white;
	background-color: #714d6d;
	font-size: 1.09rem;
	border-right: 1px solid black;
	padding: 35px 15px;
}

.td__bcolor {
	font-size: 2.87rem;
	text-align: left;
	vertical-align: text-top;
}

tr:last-child .lastone {
	border: 2px solid dodgerblue;
}

.td-spancol {
	text-align: center;
}

.public-cible {
	border: 5px solid transparent;
	border-radius: 12px;
	padding: 15px;
	margin: 38px 75px;
	background-color: rgb(181 39 28 / 83%);
	color: white;
	font-weight: bold;
	font-size: 3rem;
}

.public-cible--title {
	font-size: 6.15rem;
}

.public-cible--content {
	margin-top: 20px;
}

.legal--bordure {
	border: 3px solid rgb(51, 0, 51);
	border-radius: 15px;
	padding: 0;
	margin: 0;
	box-shadow: 6px 5px 5px rgb(48, 1, 48);
}

.tempo-margin-b {
	margin-bottom: 40px;
}

@media screen and (min-width: 360px) {
	.legal--bordure {
		margin: 271px 0;
	}
}

@media screen and (min-width: 720px) {
	.thead__bcolor {
		font-size: 1.33rem;
	}

	.td__bcolor {
		font-size: 1.4rem;
	}
}

@media screen and (min-width: 800px) {
	div[class='tableExample'] {
		padding: 25px 25px 75px;
	}
}

@media screen and (min-width: 990px) and (min-width: 1201px) {
	#cloud-container {
		margin: 0;
	}

	div[class='tableExample'] {
		padding: 0px 25px 75px;
	}
}

@media screen and (min-width: 1201px) {
	caption[class='title_iae_legal'] {
		font-size: 1.1rem;
	}

	div[class='tableExample'] {
		margin-top: 0;
	}

	#cloud-container {
		margin: 0 5%;
	}
}

@media screen and (min-width: 1376px) {
	div > #cloud-container {
		margin: 0 25%;
	}

	div .tableExample {
		margin-top: 30px;
	}

	.thead__bcolor {
		padding: 10px 15px;
		font-size: 1.09rem;
	}

	.td__bcolor {
		font-size: 0.8rem;
	}

	.legal--bordure {
		margin-top: 122px;
		margin-bottom: 60px;
	}

	.public-cible {
		font-size: 0.95rem;
		padding: 15px;
		margin: 0 75px;
		max-width: 350px;
	}

	.public-cible--title {
		font-size: 1.45rem;
	}
}
