.thanks__description {
	flex-wrap: wrap;
	text-indent: 2%;
	padding: 30px 10px 0 10px;
	color: rgb(0, 112, 192);
	font-size: 3.85rem;
	line-height: 160%;
	font-weight: 700;
}

.thanks__partenariat {
	display: inline-block;
	list-style-type: square;
	text-align: left;
	padding: 0px;
	margin-top: 25px;
	margin-bottom: 105px;
	font-size: 1.5em;
}

.partenariat {
	font-weight: 700;
	padding-left: 20px;
}

@media screen and (min-width: 320px) {
	.thanks__partenariat {
		margin-left: 36px;
		margin-right: 10px;
		margin-bottom: 25px;
		font-size: 2.5em;
	}

	.signature {
		margin: 50px 10px 0px;
	}
}

@media screen and (min-width: 360px) {
	.thanks__partenariat {
		margin-left: 39px;
	}
}

@media screen and (min-width: 768px) {
	.partenariat {
		margin-left: 110px;
	}

	.signature {
		margin-left: 50px;
	}
}

@media screen and (min-width: 885px) {
	.thanks__partenariat {
		margin-left: 39px;
	}
}

@media all and (min-width: 992px) {
	.thanks__description {
		font-size: 1.85rem;
	}
}

@media all and (min-width: 1024px) {
	.thanks__description {
		font-size: 2.85rem;
	}
}

@media screen and (min-width: 1281px) {
	.thanks__description {
		font-size: 1.75rem;
		line-height: 160%;
		font-weight: 700;
	}
}

@media screen and (min-width: 1316px) {
	.thanks__partenariat {
		margin-left: 39px;
		font-size: 1.1em;
	}
}
