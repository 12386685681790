.box-form-title {
	color: #007bff;
	padding-left: 10px;
	font-size: 2em;
	margin: 6rem 0 1rem;
	padding-top: 20px;
	text-transform: uppercase;
}

.box-form-contact {
	margin: 40px auto;
	max-width: 96%;
	display: flex;
	flex-direction: column;
}

.box-form-input {
	margin-bottom: 10px;
	padding: 5px;
	border-radius: 5px;
}

.box-form-btn {
	padding: 5px;
	border-radius: 5px;
	margin-bottom: 10px;
}

.box-form-label {
	color: #595959;
	font-weight: bolder;
}

.box-form {
	border: 0.9px solid rgb(219, 219, 219);
	border-radius: 14px;
	padding: 30px 10px;
	background-color: rgb(254, 254, 255);
}

.box-form-submitted-msg {
	margin-top: 110px;
	font-weight: 500;
}

.box-form-captcha-msg {
	font-weight: 500;
}

.box-captcha {
	margin-bottom: 15px;
}

.box-captcha-msg,
.box-captcha-title {
	margin: 8px;
}

.form-footer {
	padding-left: 15px;
	font-size: 14px;
}

.label-required {
	padding: 3px;
	font-size: 1.2rem;
	color: red;
}

.box-form-subtitle {
	margin: 29px 13px;
}

.box-form-btn {
	margin-top: 45px;
}

@media screen and (min-width: 680px) {
	.box-form {
		margin: 75px 75px;
		padding: 50px 35px;
		min-width: 400px;
	}

	.box-form-title {
		margin-top: 1rem;
	}
}

@media screen and (min-width: 1000px) {
	.box-form {
		margin: 120px auto;
		max-width: 800px;
	}
}
