.footer-wrapper {
	display: flex;
	justify-content: center;
	align-items: center;
	background-color: #f2f1f1;
	padding: 44px 0;
	font-size: 3rem;
	height: 9.5rem;
	position: absolute;
	bottom: 0;
	width: 100%;
}

@media all and (min-width: 992px) {
	.footer-wrapper {
		padding: 44px 0;
		font-size: 1.2rem;
	}
}

@media all and (min-width: 1200px) {
	.footer-wrapper {
		font-size: 1rem;
		height: 1rem;
	}
}
