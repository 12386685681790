* {
	margin: 0;
	padding: 0;
	box-sizing: border-box;
}

/* .navbar {
	background-color: rgb(89, 188, 245);
} */

html {
	min-height: 100%;
	/* make sure it is at least as tall as the viewport */
	position: relative;
}

body {
	height: 100%;
	/* force the BODY element to match the height of the HTML element */
}

/* logos */
/* logos header */
.europe-fr {
	height: 265px;
}

.plie-lievin {
	height: 306px;
}

/* logos footer */
.rf-ministeres {
	height: 353px;
	padding-right: 10px;
}

.haut-de-france {
	height: 283px;
	padding-right: 10px;
}

.france-travail {
	height: 231px;
	padding-right: 25px;
}

.pas-de-calais_depart {
	background-color: white;
	height: 86px;
	padding-right: 10px;
}

.commu-agglo-ll {
	height: 231px;
}

.commu-agglo-hc {
	height: 245px;
	padding-right: 87px;
}

.logo {
	display: block;
}

.image {
	display: inline-block;
}

.logo--space {
	padding-right: 5px;
}

/* contenu principal */
.home {
	background-color: white;
	display: flex;
	flex-direction: column;
	justify-content: center;
	text-align: center;
}

.home__logo--header {
	background-color: white;
	display: flex;
	flex-direction: row;
	justify-content: space-evenly;
	border: 2px solid rgb(0, 0, 189);
	border-radius: 15px;
	align-items: center;
	flex-wrap: wrap;
	margin-top: 255px;
}

.home__logo--footer {
	padding: 15px;
	margin: 0 0 17px;
	border: 2px solid rgb(0, 0, 189);
	border-radius: 15px;
	display: flex;
	flex-direction: row;
	flex-wrap: wrap;
	justify-content: center;
	align-items: center;
	background-color: white;
}

.home__content {
	background-color: white;
	margin-bottom: 50px;
	text-align: center;
	border: 2px solid rgb(0, 0, 189);
	border-radius: 19px;
}

.home__header {
	height: 81px;
	background-color: rgb(0, 0, 189);
	border-radius: 15px 15px 0 0;
}

.home__title {
	padding: 47px 8px 0 8px;
	color: rgb(0, 112, 192);
	font-weight: 800;
	line-height: 117%;
	font-size: 5.55rem;
}

.home__description {
	flex-wrap: wrap;
	text-indent: 2%;
	padding: 30px 10px 0 10px;
	color: rgb(0, 112, 192);
	font-size: 3.85rem;
	line-height: 160%;
	font-weight: 700;
}

.home__image--footer {
	display: flex;
	flex-direction: row;
	flex-wrap: wrap;
	justify-content: space-evenly;
	margin: 10px 15px;
}

/* bordure du span header */
.border-bottom {
	display: inline-block;
	width: 75%;
	border-bottom: 2px solid rgb(51, 0, 51);
	margin-left: 55px;
}

.organizations {
	display: flex;
	flex-direction: row;
	justify-content: space-evenly;
	flex-wrap: wrap;
	font-weight: bold;
	font-family: 'Cabin', sans-serif;
	margin: 50px 5px 50px;
}

div.organization-ai,
div.organization-aci,
div.organization-ei,
div.organization-etti {
	font-size: 14.2rem;
	border-radius: 80px;
	margin-top: 20px;
}

div.organization-ai > a,
div.organization-aci > a,
div.organization-ei > a,
div.organization-etti > a {
	color: white;
	text-decoration: none;
	vertical-align: middle;
}

/* AI */
div.organization-ai {
	background-color: rgb(210, 46, 77);
	line-height: 47px;
	padding: 104px 54px;
}

div.organization-ai:visited {
	background-color: rgb(210, 46, 77);
}

div.organization-ai:hover {
	background-color: rgba(210, 46, 76, 0.507);
}

div.organization-ai:active {
	background-color: rgba(128, 24, 43, 1);
}

/* ACI */
div.organization-aci {
	background-color: rgb(122, 163, 41);
	line-height: 47px;
	padding: 104px 54px;
}

div.organization-aci:visited {
	background-color: rgb(122, 163, 41);
}

div.organization-aci:hover {
	background-color: rgb(122, 163, 41, 0.507);
}

div.organization-aci:active {
	background-color: rgba(82, 110, 27, 1);
}

/* EI */
div.organization-ei {
	background-color: rgb(98, 41, 123);
	line-height: 47px;
	padding: 104px 54px;
}

div.organization-ei:visited {
	background-color: rgb(98, 41, 123);
}

div.organization-ei:hover {
	background-color: rgb(98, 41, 123, 0.507);
}

div.organization-ei:active {
	background-color: rgba(54, 23, 68, 1);
}

/* ETTI */
div.organization-etti {
	background-color: rgb(214, 87, 42);
	line-height: 47px;
	padding: 104px 54px;
}

div.organization-etti:visited {
	background-color: rgb(214, 87, 42);
}

div.organization-etti:hover {
	background-color: rgb(214, 87, 42, 0.507);
}

div.organization-etti:active {
	background-color: rgba(124, 50, 23, 1);
}

@media screen and (min-width: 360px) {
	#cloud-container {
		margin: 0 1%;
	}

	.home__logo--header {
		margin-top: 260px;
		margin-bottom: 32px;
	}

	.plie-lievin {
		height: 327px;
	}

	.europe-fr {
		height: 335px;
	}

	.rf-ministeres {
		height: 417px;
	}

	.haut-de-france {
		height: 345px;
	}

	.pas-de-calais_depart {
		height: 121px;
	}

	.commu-agglo-ll {
		height: 280px;
		margin-top: 39px;
	}

	.commu-agglo-hc {
		height: 235px;
	}

	.home__content {
		margin-bottom: 32px;
	}

	.home__logo-footer {
		margin-bottom: 63px;
	}

	.france-travail {
		height: 235px;
	}
}

@media all and (min-width: 992px) {
	.home__logo--header {
		margin-top: 139px;
	}

	#cloud-container {
		margin: 0 12%;
	}

	.europe-fr {
		height: 141px;
	}

	.plie-lievin {
		height: 189px;
	}

	.europe-fr {
		height: 143px;
	}

	.plie-lievin {
		height: 180px;
	}

	.rf-ministeres {
		height: 139px;
	}

	.haut-de-france {
		height: 136px;
	}

	.pas-de-calais_depart {
		height: 40px;
	}

	.commu-agglo-ll {
		height: 107px;
	}

	.commu-agglo-hc {
		height: 100px;
	}

	.france-travail {
		height: 100px;
	}

	.home__title {
		font-size: 2.55rem;
	}

	.home__description {
		font-size: 1.85rem;
	}

	div.organization-ai,
	div.organization-aci,
	div.organization-ei,
	div.organization-etti {
		font-size: 5.2rem;
		border-radius: 28px;
	}

	div.organization-ai {
		line-height: 47px;
		padding: 51px 33px;
	}

	div.organization-aci {
		line-height: 47px;
		padding: 51px 33px;
	}

	div.organization-ei {
		line-height: 47px;
		padding: 51px 33px;
	}

	div.organization-etti {
		line-height: 47px;
		padding: 51px 33px;
	}
}

@media all and (min-width: 1024px) {
	#cloud-container {
		margin: 0 4%;
	}

	.europe-fr {
		height: 237px;
	}

	.plie-lievin {
		height: 270px;
	}

	.rf-ministeres {
		height: 287px;
	}

	.haut-de-france {
		height: 240px;
	}

	.pas-de-calais_depart {
		height: 79px;
	}

	.commu-agglo-ll {
		height: 204px;
	}

	.commu-agglo-hc {
		height: 203px;
	}

	.france-travail {
		height: 203px;
	}

	.home__title {
		font-size: 3.55rem;
	}

	.home__description {
		font-size: 2.85rem;
	}

	div.organization-ai,
	div.organization-aci,
	div.organization-ei,
	div.organization-etti {
		margin: 1% 0.1%;
	}
}

@media screen and (min-width: 1281px) {
	#cloud-container {
		margin: 0 25%;
	}

	.home__logo--header,
	.home__logo--footer,
	.home__content {
		border: 1px solid rgb(0, 0, 189);
	}

	.home__title {
		font-size: 300%;
		line-height: 52px;
		font-weight: bolder;
	}

	.home__description {
		font-size: 1.75rem;
		line-height: 160%;
		font-weight: 700;
	}

	.home__header {
		height: 35px;
		border-radius: 13px 13px 0 0;
	}

	.home__logo--header {
		margin-top: 115px;
		padding: 0;
		padding-top: 1px;
	}

	/* .home__logo--header,
	.home__content,
	.home__logo--footer {
		margin-left: 25%;
		margin-right: 25%;
	} */

	.europe-fr {
		height: 143px;
	}

	.plie-lievin {
		height: 180px;
	}

	.rf-ministeres {
		height: 139px;
	}

	.haut-de-france {
		height: 136px;
	}

	.pas-de-calais_depart {
		height: 40px;
	}

	.commu-agglo-ll {
		height: 107px;
	}

	.commu-agglo-hc {
		height: 100px;
	}

	.france-travail {
		height: 100px;
	}

	.organizations {
		padding-bottom: 16px;
		padding-top: 30px;
		justify-content: space-evenly;
	}

	div.organization-ai,
	div.organization-aci,
	div.organization-ei,
	div.organization-etti {
		border-radius: 22px;
		font-size: 5em;
	}

	div.organization-ai {
		line-height: 42px;
		padding: 30px 20px;
	}

	div.organization-aci {
		line-height: 42px;
		padding: 30px 20px;
	}

	div.organization-ei {
		line-height: 42px;
		padding: 30px 20px;
	}

	div.organization-etti {
		line-height: 42px;
		padding: 30px 20px;
	}
}
