.edito__title {
	font-weight: 900;
	margin-top: 30px;
	font-size: 6em;
}

.edito__content {
	text-align: left;
	padding: 30px 45px;
	font-size: 3em;
}

.edito__left {
	background-color: rgb(158, 183, 200);
}

.edito--bordure {
	border: 3px solid rgb(51, 0, 51);
	border-radius: 15px 15px 15px 15px;
	margin-top: 286px;
	padding: 0;
	box-shadow: 6px 5px 5px rgb(48, 1, 48);
}

.edito__head--bordure {
	height: 130px;
	width: 100%;
	border: 15px solid rgb(51, 0, 51);
	border-radius: 13px 13px 0 0;
	background-color: rgb(51, 0, 51);
	display: flex;
	flex-wrap: wrap;
	padding-top: 20px;
	margin-bottom: 10px;
}

.edito__signature--block {
	display: flex;
	/* flex-direction: row;
	justify-content: space-between; */
	margin-top: 25px;
	margin-bottom: 100px;
	font-size: 3.6em;
	font-weight: 600;
}

.edito__signature {
	text-align: left;
}

.sign__left {
	padding-left: 40px;
}

.sign__right {
	padding-left: 10px;
}

div[class='edito__logos'] > div[class='home__logo--header'] {
	border: none;
}

@media screen and (min-width: 360px) {
	.edito__signature {
		width: 100%;
	}
}

@media screen and (min-width: 563px) {
	.edito__title {
		margin-top: 5px;
	}
}

@media screen and (min-width: 563px) {
	.edito__logos {
		margin-top: -209px;
	}
}

@media screen and (min-width: 990px) {
	.edito--bordure {
		margin-top: 270px;
	}

	.edito__head--bordure {
		height: 87px;
		border-radius: 13px 13px 0 0;
		background-color: #303;
		display: flex;
		flex-wrap: wrap;
		padding-top: 20px;
		margin: -2px 0px 10px;
	}

	.edito__logos {
		margin-top: -98px;
	}

	.edito__title {
		font-size: 4em;
	}
	.edito__content {
		font-size: 2em;
	}

	.edito__signature {
		text-align: center;
	}

	.edito__signature--block {
		font-size: 1.9em;
	}
}

@media screen and (min-width: 992px) {
	div > #cloud-container {
		margin: 0 8%;
	}

	.edito__content {
		font-size: 1rem;
		padding: 30px 53px;
	}

	.edito__title {
		font-size: 2rem;
	}
	.edito__logos {
		margin: -98px 20px 0;
	}
	.edito--bordure {
		margin-top: 91px;
	}

	.edito__signature--block {
		font-size: 1.2em;
	}
}
@media screen and (min-width: 1024px) {
	.edito--bordure {
		max-width: 860px;
		margin: 117px auto 35px;
	}

	.edito__content {
		padding: 40px 100px;
	}

	.editor__head--bordure {
		height: 30px;
		width: 120%;
		border: 15px solid #303;
		border-radius: 13px 13px 0 0;
		background-color: #303;
		display: flex;
		flex-wrap: wrap;
		padding-top: 20px;
		margin: -2px -2px 10px;
	}

	div[class='edito__title'] {
		font-size: 2rem;
	}

	.edito__signature--block {
		margin-top: 13px;
		margin-bottom: 70px;
		font-size: 1.1em;
	}
}
