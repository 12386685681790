.box-legal-title {
	font-size: 2em;
	margin: 0 0 1rem;
	padding-top: 20px;
	text-transform: uppercase;
}

.box-legal-title::before {
	content: '';
	height: 5px;
	width: 75px;
	background-color: #007bff;
	display: block;
	left: 3px;
	position: relative;
	margin-top: 80px;
	margin-bottom: 15px;
}

.box-legal {
	border: 0.9px solid rgb(219, 219, 219);
	border-radius: 14px;
	padding: 30px 25px;
	background-color: rgb(254, 254, 255);
}

.box-legal-article-title {
	color: #007bff;
	font-size: 1.7em;
	margin: 0 0 1rem;
	padding-top: 20px;
}

.box-legal-article-title::before {
	content: '';
	height: 5px;
	width: 75px;
	background-color: #007bff;
	display: block;
	left: 3px;
	position: relative;
	margin-bottom: 15px;
}

.box-legal-article-title::after {
	content: '';
	height: 5px;
	display: block;
	left: 3px;
	position: relative;
	margin-bottom: 25px;
}

.box-legal-h3 {
	font-size: 1.3em;
	color: #007bff;
}

.box-legal-h3::before {
	content: '';
	height: 5px;
	display: block;
	left: 3px;
	position: relative;
	margin-top: 15px;
}

.box-legal-h3::after {
	content: '';
	height: 5px;
	display: block;
	left: 3px;
	position: relative;
	margin-bottom: 15px;
}

.box-legal-h4 {
	font-size: 1.15em;
	font-weight: bold;
}

.box-legal-title,
.box-legal-article-title {
	font-weight: 500;
	color: #007bff;
}

.box-legal-subtitle {
	padding: 12px;
	text-align: center;
}

.address-bold {
	font-weight: 600;
}

.link-contact {
	margin-top: 10px;
}

.link-hov:hover {
	color: #032d91;
}

@media screen and (min-width: 800px) {
	.box-legal {
		margin: 100px auto;
	}
}

@media screen and (min-width: 1200px) {
	.box-legal {
		max-width: 900px;
	}
}
