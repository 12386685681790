.title-glossary {
	font-weight: 900;
	margin-top: 12px;
	margin-bottom: 25px;
}

.glossary__block {
	border: 3px solid rgb(51, 0, 51);
	border-radius: 15px 15px 15px 15px;
	box-shadow: 6px 5px 5px rgb(48, 1, 48);
	max-width: 860px;
	margin: 150px 35px 10px;
	padding: 15px;
	max-width: 100%;
}

.glossary {
	padding-top: 0px;
}

.glossaire__content {
	background-color: white;
	text-align: left;
	padding: 0px 8px;
}

.glossary--text {
	font-size: 2rem;
	font-weight: 900;
}

.acronyms {
	display: inline-block;
	list-style-type: none;
	margin-bottom: 25px;
}

.tree_books {
	height: 350px;
	margin: 0 auto;
	margin-bottom: 52px;
}

.glossaire--bordure {
	border: 3px solid rgb(51, 0, 51);
	border-radius: 15px 15px 15px 15px;
	padding: 0;
	margin: 0;
	box-shadow: 6px 5px 5px rgb(48, 1, 48);
}

.bold {
	font-weight: bold;
}

@media screen and (min-width: 414px) {
	.glossaire__content {
		padding: 0 18px;
	}
}

@media screen and (min-width: 768px) {
	.glossaire--bordure {
		margin: 0 45px;
	}

	.glossaire__content {
		padding: 0 75px;
	}

	.title-glossary {
		margin-top: 25px;
	}
}

@media screen and (min-width: 1024px) {
	.glossaire--bordure {
		margin: 117px auto 35px;
	}
}
