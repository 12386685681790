table {
  table-layout: initial;
  width: 100%;
}

.table th, .table td {
  padding: 1.18rem;
  text-align: center;
}

.table thead th {
  vertical-align: middle;
  text-align: center;
}

.table td {
  text-align: left;
}

/* Description */
.table th:nth-child(3) {
  padding: 0 75px;
}

/* Statut juridique */
.table th:nth-child(5) {
  padding: 0 48x;
}

/* Personne à contacter */
.table th:nth-child(9) {
  padding: 0 90px;
}

/* Adresse postale */
.table th:nth-child(10) {
  padding: 0 85px;
}

/* Domaine pro */
.table th:nth-child(15) {
  padding: 0 85px;
}

/* Les autres sites */
.table th:nth-child(17) {
  padding: 0 125px;
}

/* Description iae */
.table th:nth-child(20) {
  padding: 10px 115px;
}

/* Débouchés pro */
.table th:nth-child(21) {
  padding: 10px 85px;
}

/* Status */
.table th:nth-child(22) {
  padding: 0px 72px;
}

.margin-top-12 {
  margin-top: 238px;
}

.margin-top-80 {
  margin-top: 80px;
}

.padding-left-65 {
  padding-left: 65px;
}

.pagination-center {
  display: flex;
  justify-content: center;
}

.pre-title {
  margin-top: 50px;
}


