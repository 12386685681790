.home__right-hehe {
	width: 35%;
	background-color: rgb(0, 112, 192);
}

#top-tag {
	margin-top: 154px;
}

.edit-form-initial {
	max-width: 600px;
	margin: auto;
}

.loop__structures {
	list-style: square;
}

.loop__structures2 {
	list-style: square;
	margin-bottom: 100px;
}

.first-li {
	margin-top: 20px;
}

.bold {
	font-weight: bold;
}

.indent {
	margin-left: 160px;
}

.title {
	margin: 65px 150px;
	text-align: center;
}

.central {
	padding: 50px 25px;
}

.title-sub-pos {
	text-align: center;
}

/* .top-margin-25 {
	margin-top: 25px;
}

.top-padding-120 {
	padding-top: 120px;
}

.top-65 {
	margin-top: 65px;
}

.top-130 {
	margin-top: 130px;
}

.top-box-form {
	margin-top: 140px;
} */

.link-padding {
	padding-left: 45px;
}

.li-hover:hover {
	background-color: rgb(62, 186, 235);
	text-decoration: none;
}

.li-hover:hover > a {
	color: white !important;
}

.li-hover {
	padding: 0px 5px;
}

@media screen and (min-width: 360px) {
	/* .top-box-form {
		margin-top: 132px;
	} */
}
