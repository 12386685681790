.lesPlies--bordure {
	border: 3px solid rgb(51, 0, 51);
	border-radius: 15px 15px 15px 15px;
	margin: 286px 0;
	padding: 0;
	box-shadow: 6px 5px 5px rgb(48, 1, 48);
}

.list-lesPlies::before {
	list-style: none;
	list-style-position: inside;
	content: '\27A2';
}

.list-lesPlies {
	list-style: none;
	padding: 15px 0 0 20px;
}

.list__coord {
	list-style: none;
	font-size: 0.93rem;
	padding-bottom: 25px;
}

.list__block-coord {
	text-align: center;
	padding: 0 50px;
}

.first__arg {
	display: inline-block;
	text-align: left;
	padding-left: 35px;
}

.bold {
	font-weight: bold;
}

.lesPlies--bordure {
	border: 3px solid rgb(51, 0, 51);
	border-radius: 15px 15px 15px 15px;
	padding: 0;
	margin: 0;
	box-shadow: 6px 5px 5px rgb(48, 1, 48);
}

.plie {
	display: block;
	padding-bottom: 8px;
}

.lesPlies__title {
	font-weight: 900;
	margin-top: 30px;
}

@media screen and (min-width: 360px) {
	.lesPlies--bordure {
		margin: 20px 0px;
		padding: 0;
	}

	.lesPlies__title {
		margin: 30px 5px 0px;
	}

	.first__arg {
		padding-left: 0;
	}

	.list__block-coord {
		text-align: unset;
		padding: 0;
	}

	.list__coord {
		display: flex;
		margin-left: 26px;
	}

	.list__coord--begin {
		margin-left: 13px;
	}

	.coord__span {
		display: block;
		padding-top: 10px;
	}
}

@media screen and (min-width: 563px) {
	.lesPlies__title {
		margin-top: 5px;
	}
}

@media screen and (min-width: 706px) {
	.lesPlies__content {
		padding: 0 25px;
		text-align: left;
	}

	.lesPlies__title {
		margin-bottom: 30px;
	}

	.page__content--lesPlies {
		padding: 0 25px;
	}
}

@media screen and (min-width: 768px) {
	.lesPlies__title {
		margin: 30px 30px 30px 15px;
	}

	.lesPlies__content {
		padding: 0 100px;
	}
}

@media screen and (min-width: 800px) {
	.lesPlies--bordure {
		margin: 20px 75px;
		padding: 0;
	}

	.coord__span {
		display: inline;
	}

	.list__coord {
		display: inline-block;
	}

	.list__block-coord {
		display: flex;
		justify-content: space-evenly;
	}

	.plie-ll {
		padding-left: 21px;
		padding-right: 5px;
	}

	.plie-hc {
		padding-left: 5px;
	}
}

@media screen and (min-width: 1376px) {
	.lesPlies--bordure {
		margin-top: 122px;
		margin-bottom: 60px;
	}

	.lesPlies__content {
		padding: 10px 46px;
	}
}
