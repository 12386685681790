.disabledCursor { 
  cursor: default;
}

.icon-copy {
  display: inline-block;
  height: 35px;
}

.icon-copy:hover {
  background-color: chartreuse;
}

.icon-bg {
  background-color: white;
  border: none;
  margin: 0;
  padding: 0;
}

.message-display {
  display: inline-block;
  padding-left: 50px;
  font-weight: bold;
}

.container-inline {
  display: inline-block;
}

#gallery-photo-add {
  margin-top: 50px;
}

@media screen and (min-width: 360px)  {
  .add-tuto-top {
    margin-top: 115px;
  }
   #gallery-photo-add {
    margin-top: 50px;
  }
}

@media screen and (min-width: 800px)  {
  .add-tuto-top {
    margin-top: 100px;
  }
  #gallery-photo-add {
    margin-top: 0px;
  }
}



