.top-115 {
  margin-top: 115px;
}

.bold {
  font-weight: bold;
}

.bottom-100 {
  margin-bottom: 100px;
}

.box__element {
  display: inline-block;
  width: 25%;
  background-color: blueviolet;
  border: 1px solid black;
  text-align: center;
}

.block {
    margin-bottom: 40px;
    padding-top: 17px;
}

.block--1link {
  display: flex;
  justify-content: center;
}

.block--2links {
  display: flex;
  justify-content: space-evenly;
}

.link-next-prev {
  display: inline-block;
  border: 2px solid #007bff;
  color: #007bff;
  background-color:  #fff;
  padding: 8px;
  border-radius: 5px;
}

.link-next-prev:hover {
  color: #fff;
  background-color: #007bff;
  border: 2px solid #007bff;
  padding: 8px;
  border-radius: 5px;
}

@media print {
  html, body {
    height: 99%;
    margin: 0;
    padding: 0;
  }
}
