.jumbotron-top {
	margin-top: 120px;
}

.marg-bottom-40 {
	margin-bottom: 40px;
}

.div-jumbo {
	margin-top: 114px;
}

.top-profil {
	margin-top: 90px;
}
