/* Branche dev */

/* Logos */
.logoStructure {
	display: block;
	/* background-repeat: no-repeat;
  background-size: 100%; */
	margin: 50px auto 25px;
}

.list-files {
	height: 240px;
	overflow: auto;
}

/* ************ Début AI ************ */
/* AIAAC */
.structure5 {
	width: 350px;
}

/* APSA ALE COUP D'MAIN */
.structure15 {
	width: auto;
}

/* PARTENAIRES ACTION */
.structure25 {
	width: 324px;
}
/* RELAIS TRAVAIL */
.structure35 {
	width: 324px;
}

/* SAPI */
.structure45 {
	width: 285px;
}

/* SAPI INSERTION */
.structure55 {
	width: 280px;
}

/* ************ Fin AI ************ */

/* ************ Début ACI ************ */

/* ACTIV'CITES */
.structure65 {
	width: auto;
}

/* 3 ID */
.structure75 {
	width: 372px;
}

/* ADDS */
.structure85 {
	width: 274px;
}

/* LES ANGE GARDINS */
.structure95 {
	width: auto;
}

/* APSA */
.structure105 {
	width: auto;
}

/* DIE */
.structure115 {
	width: 250px;
}

/* EL FOUAD */
.structure125 {
	width: 312px;
}

/* IMPULSION */
.structure135 {
	width: 310px;
}

/* INITIATIVE SOLIDAIRE */
.structure145 {
	width: auto;
}

/* RECUP'TRI */
.structure155 {
	height: 257px;
}

/* VESTALI */
.structure165 {
	height: 200px;
}

/* PACTE62 */
.structure175 {
	height: 227px;
}

/* AFEJI */
.structure305 {
	width: 319px;
}

/* ************ Fin ACI ************ */

/* ************ Début EI ************ */

/* ACTIV'CITES */
.structure185 {
	width: auto;
}

/* ADDS */
.structure195 {
	width: 274px;
}

/* DIE */
.structure205 {
	width: 250px;
}

/* GOHELLE ENVIRONNEMENT */
.structure215 {
	width: auto;
}

/* IMPULSION */
.structure225 {
	width: 310px;
}

/* LA LOCOMOTIVE */
.structure235 {
	width: 296px;
}

/* MAIN FORTE */
.structure245 {
	width: 220px;
}

/* SINEO */
.structure255 {
	width: 240px;
}

/* Les COUSALIS */
.structure265 {
	width: 240px;
}

/* ENVIE NORD */
.structure315 {
	width: 319px;
}

/* NINSERTION */
.structure325 {
	width: 319px;
}

/* ************ Fin EI ************ */

/* ************ Début ETTI ************ */
/* INTERINSER */
.structure275 {
	height: 200px;
}

/* JANUS SAS */
.structure285 {
	width: 310px;
}

/* PARTENAIRES INTERIM */
.structure295 {
	width: 319px;
}

/************* Fin ETTI ************ */
