html,
body,
#root {
	width: 100%;
	height: 100%;
}

div > table {
	margin: 0 auto;
}

table {
	table-layout: auto;
	width: 100%;
}

table,
td,
th {
	border: 1px solid #333;
	font-weight: bold;
	padding: 15px;
}

tbody {
	text-align: center;
}

thead,
th,
tfoot {
	background-color: #fff;
	color: black;
	text-align: center;
}

div > #cloud-container {
	margin: 0;
}

.tableExample {
	text-align: center;
	margin-top: 50px;
	padding: 25px 0 75px;
}

.td-box {
	font-size: 2.8rem;
}

.iae__content {
	text-align: left;
	font-size: 3rem;
	padding: 30px 0;
}

.iae__title {
	font-size: 3.68rem;
	margin: 30px 15px 15px;
	display: inline-block;
	font-weight: 900;
}

.iae__assos {
	display: inline-block;
	padding-top: 50px;
	padding-bottom: 20px;
	margin-bottom: 15px;
	font-size: 2.8rem;
	font-weight: 700;
}

.iae__head--bordure {
	height: 125px;
	width: 120%;
	border: 15px solid #303;
	border-radius: 13px 13px 0 0;
	background-color: #303;
	display: flex;
	flex-wrap: wrap;
	padding-top: 20px;
	margin: -2px -2px 10px;
}

.padd {
	padding: 0 15px;
}

.arrow-right {
	max-width: 75px;
	margin: 0 auto;
}

.arrow-up-right {
	max-width: 100%;
	height: auto;
	margin: auto auto;
	padding-bottom: 10px;
}

.li-color {
	list-style-type: none;
	padding: 10px 10px 73px 35px;
	font-weight: bold;
}

.li-color:hover,
.li-color:hover > a {
	background-color: rgb(62, 186, 235);
	color: white;
	text-decoration: none;
}

.li__cat {
	display: inline-block;
	height: 150px;
	width: 100%;
	margin-right: 35px;
}

.li__cat--word {
	display: inline-block;
	font-size: 2.8rem;
	max-width: 100%;
}

.structure__spanGroup {
	display: flex;
	align-items: center;
}

.li-ai {
	background-color: rgb(210, 46, 77);
	border: 1px solid rgb(210, 46, 77);
}

.li-aci {
	background-color: rgb(122, 163, 41);
	border: 1px solid rgb(122, 163, 41);
}

.li-ei {
	background-color: rgb(93, 21, 124);
	border: 1px solid rgb(93, 21, 124);
}

.li-etti {
	background-color: rgb(214, 87, 42);
	border: 1px solid rgb(214, 87, 42);
}

.ul-colors {
	margin-top: 0;
}

.iae_first_para {
	margin-bottom: 40px;
}

.iae--bordure {
	border: 3px solid rgb(51, 0, 51);
	border-radius: 15px;
	padding: 0;
	margin: 0;
	box-shadow: 6px 5px 5px rgb(48, 1, 48);
}

.table-iae {
	margin-top: 41px;
}

@media screen and (min-width: 360px) {
	.iae--bordure {
		margin: 20px 0px;
		padding: 0;
	}

	.text-padding {
		padding: 17px;
	}

	.li__cat--word {
		max-width: 197px;
	}

	.padd {
		padding: 0 32px;
	}
}

@media screen and (min-width: 414px) {
	.iae--bordure {
		margin: 139px 150px;
	}

	.text-padding {
		padding: 10px;
	}

	.padd {
		padding: 0 32px;
	}
}

@media screen and (min-width: 452px) {
	.li__cat--word {
		max-width: 234px;
	}
}

@media screen and (min-width: 706px) {
	.iae--bordure {
		margin: 20px 75px;
		padding: 0;
	}

	.li__cat--word {
		max-width: 315px;
	}
}

@media screen and (min-width: 769px) and (max-width: 950px) {
	.li__cat--word {
		max-width: 400px;
	}

	.iae__content {
		padding: 30px 6px;
	}

	#cloud-container {
		margin: 0 100px;
	}
}

@media screen and (min-width: 951px) and (max-width: 1080px) {
	#cloud-container {
		margin: 0 10%;
	}

	.iae__content {
		padding: 30px 50px;
	}

	.iae--bordure {
		margin: 281px 0 50px;
		max-width: 100%;
	}
}

@media screen and (min-width: 950px) {
	#cloud-container {
		margin: 0 100px;
	}

	.iae__content {
		padding: 30px 50px;
	}
}

@media screen and (min-width: 1081px) and (max-width: 1329px) {
	#cloud-container {
		margin: 0 10%;
	}

	.td-box {
		font-size: 1rem;
	}

	.padd {
		padding: 0 15px;
		font-size: 1.28rem;
	}

	.iae__title {
		font-size: 1rem;
	}

	.iae__content {
		padding: 30px 50px;
		font-size: 1rem;
	}

	.iae__assos {
		font-size: 1rem;
	}

	.li__cat {
		height: 69px;
		width: 132px;
		margin-right: 35px;
	}

	.li__cat--word {
		font-size: 1.2rem;
	}
}

@media screen and (min-width: 1281px) {
	.tableExample {
		padding: 5px;
	}
}

@media screen and (min-width: 1330px) {
	div #cloud-container {
		margin: 0 25%;
	}

	.td-box {
		font-size: 1rem;
	}

	.padd {
		padding: 0 15px;
	}

	.iae__title {
		font-size: 1.68rem;
	}

	.iae__content {
		padding: 30px 50px;
		font-size: 1rem;
	}

	.iae__assos {
		font-size: 1rem;
		padding: 0;
		font-weight: bold;
	}

	.iae__head--bordure {
		height: 30px;
	}

	.li__cat {
		height: 30px;
		width: 60px;
		margin-right: 35px;
	}

	.li__cat--word {
		font-size: 1rem;
	}

	.li-color {
		padding: 10px 10px 15px 35px;
		max-width: 534px;
	}
}

@media screen and (min-width: 1376px) {
	.iae--bordure {
		margin-top: 122px;
		margin-bottom: 60px;
	}
}
