html, body {
  padding: 0;
  margin: 0;
}

.tableExample {
  margin-top: 150px;
}

/* h1 {
  margin-bottom: 50px;
}

html {
    min-height: 100%;
    background: #FFA3B3;
}
  a {
      color: black;
  }
  a:hover {
          background:rgba(255,255,255,0.7);
      }
  h1 {
      font-size: 2.5em;
  }
  h2 {
      border-bottom: 3px solid #000;
  }
  code {
      font-family: SFMono-Regular,Consolas,Liberation Mono,Menlo,Courier,monospace;
      background:rgba(255,255,255,0.4);
      color: black;
      word-wrap: break-word;
  }

  ul li, ol li {
    line-height:140%; 
  }
  table {
      border-collapse: collapse;
      background:rgba(255,255,255,0.4);
  }

  th,
  td {
      border: 1px solid #000;
      padding: 0.75rem;
      text-align: left;
  }

  th {
      font-weight: bold;
      white-space: nowrap;
      background: #000;
      color: #fff;
  }

  tr:first-of-type th:not(:last-child) {
      border-right-color: transparent;
  }

  tr:first-child th:first-child,
  tr:not(:first-child):not(:last-child) th {
      border-bottom-color: transparent !important;
  }

.responsiveTable tr td.pivoted {
  overflow-wrap: normal;
}

.width {
  min-width: 150px;
} */
