.message-top {
  margin-top: 100px;
}

.image-upload {
  display: block;
  margin: 0 auto;
  height: 150px;
  width: 150px;
}

a[name]::after {
  content: '\2693';
  cursor: pointer;
}

div.preview-images > img {
  width: 30%;
  }

@media screen and (max-width: 640px)  {
  tr td.pivoted {
    overflow-wrap: normal
  }

  div td.tdBefore {
    overflow-wrap: normal
  }
}