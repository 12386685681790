/* Optional: Makes the sample page fill the window. */
html,
body {
	height: 100%;
	margin: 0;
	padding: 0;
}

/* Always set the map height explicitly to define the size of the div
       * element that contains the map. */
#map {
	display: inline-block;
	height: 100%;
	width: 100%;
	margin-bottom: 50px;
}

#messages {
	height: 200px;
}

div[class~='card'] {
	padding-bottom: 5px;
}

p > button {
	width: 254.417px;
}

div[class~='card-body'],
div[class~='card-box'] {
	padding-top: 0;
}

div[class~='card-box'] > p {
	margin-bottom: 0.22rem;
}

div[class~='card-box'] > ul[class~='list-group'] {
	margin-bottom: 18px;
	max-width: 254.417px;
}

div .card {
	margin: 0px auto 15px;
}

p > .address-top {
	margin-bottom: 0;
}

.label-marker {
	/* position: absolute; */
	color: blue;
	font-size: 12px;
	/* font-weight: bold; */
	margin-top: 75px;
	margin-left: 13px;
	text-align: right;
	padding: 2px;
	border: 1px solid transparent;
	border-radius: 10px;
	background-color: rgba(255, 255, 254, 0.616);
}

.label-marker:focus {
	color: red;
}

.title__map {
	padding: 40px 0px;
	text-align: center;
	font-weight: bold;
	font-size: 1.15rem;
}

.marker-list {
	/* margin-top: 0px; */
	padding-left: 20px;
	padding-right: 15px;
	display: inline-block;
	height: 400px;
	overflow: auto;
}

.map-position {
	display: flex;
	justify-content: center;
	height: 400px;
	flex-wrap: wrap;
}

.ul-title {
	border: 1px solid black;
	list-style-type: none;
}

.ul-title li {
	padding-left: 10px;
}

.skin--ai {
	background-color: #d22e4d;
}

.skin--aci {
	background-color: #7aa329;
}

.skin--ei {
	background-color: #62297b;
}

.skin--etti {
	background-color: #d6572a;
}

.card__title {
	font-weight: bold;
}

.card__adresse {
	margin-bottom: 0px;
}

.message-localisation {
	padding-bottom: 75px;
}

.button__localisation {
	font-size: 0.82rem;
	font-weight: 700;
	background-color: #fff;
	margin-top: 4px;
	height: 31px;
	width: 110px;
	border: 1px solid #000;
	border-radius: 9px;
	opacity: 0.8;
	color: rgb(0, 0, 0);
}

@media screen and (min-width: 360px) {
	.map-position {
		margin-top: 85px;
	}

	p > button {
		max-width: 214.417px;
	}

	div[class~='card-box'] > ul[class~='list-group'] {
		max-width: 214.417px;
	}
}

@media screen and (min-width: 768px) {
	div[class~='marker-list'] {
		padding-top: 0px;
	}

	p > button {
		max-width: 136.417px;
	}

	div[class~='card-box'] > ul[class~='list-group'] {
		max-width: 136.417px;
	}
}

@media screen and (min-width: 992px) {
	.map-position {
		margin-top: 65px;
	}

	p > button {
		max-width: 211.417px;
	}

	div[class~='card-box'] > ul[class~='list-group'] {
		max-width: 211.417px;
	}
}

@media screen and (min-width: 1200px) {
	/* .map-position {
		margin-top: 45px;
	} */

	p > button {
		max-width: 255px;
	}

	div[class~='card-box'] > ul[class~='list-group'] {
		max-width: 255px;
	}

	.map-wrapper {
		margin: 45px 15% 45px;
	}
}
