html,
body {
	font: 1em/1.3;
	height: 100%;
}

label {
	display: block;
	margin-top: 10px;
	font-weight: bold;
}

th {
	margin: auto;
}

.app-container {
	position: relative;
	min-height: 100vh;
}

.app-footer {
	padding-top: 81px;
	padding-bottom: 9.5rem;
}

.navbar__title {
	font-size: 3em;
}

.toggle-size {
	font-size: 5em;
	height: 25%;
}

.nav-string {
	font-size: 3.5em;
}

.bg-perso {
	background-color: #ededed;
}

.hide {
	display: none;
}

.bold {
	font-weight: bold;
}

.unbold {
	font-weight: normal;
}

.jumbotron-top {
	margin-top: 120px;
}

.top-5 {
	margin-top: 5px;
}

.top-15 {
	margin-top: 15px;
}

.top-25 {
	margin-top: 25px;
}

.top-45 {
	margin-top: 45px;
}

.list {
	text-align: left;
	/* width: 680px; */
	margin: 90px 0px 150px;
}

.list-group {
	margin-bottom: 75px;
}

.list-group2 {
	text-align: left;
	max-width: 800px;
	margin: 90px 0px 150px;
}

.list-table {
	padding: 0;
	margin: 0;
}

.list-table .action {
	cursor: pointer;
}

.submit-form {
	max-width: 550px;
	margin: auto;
}

.add-tuto-top {
	margin-top: 100px;
}

.add-tuto-bottom {
	margin-bottom: 100px;
}

.add-tuto-bottom-top {
	margin-bottom: 25px;
}

.edit-form {
	max-width: 300px;
	margin: auto;
}

.icon-group {
	height: 100px;
	display: flex;
	justify-content: flex-start;
	align-items: flex-start;
}

.icon-edit {
	padding: 5px;
}

.badge-warning,
.badge-success,
.badge-primary,
.badge-danger {
	padding: 8px;
	font-size: 0.875rem;
	line-height: 1.5;
}

.card-container.card {
	max-width: 350px !important;
	padding: 40px 40px;
}

.card {
	background-color: #f7f7f7;
	padding: 20px 25px 30px;
	margin: 0 auto 25px;
	margin-top: 50px;
	-moz-border-radius: 2px;
	-webkit-border-radius: 2px;
	border-radius: 2px;
	-moz-box-shadow: 0px 2px 2px rgba(0, 0, 0, 0.3);
	-webkit-box-shadow: 0px 2px 2px rgba(0, 0, 0, 0.3);
	box-shadow: 0px 2px 2px rgba(0, 0, 0, 0.3);
}

.profile-img-card {
	width: 96px;
	height: 96px;
	margin: 0 auto 10px;
	display: block;
	-moz-border-radius: 50%;
	-webkit-border-radius: 50%;
	border-radius: 50%;
}

.blabla {
	display: none;
}

.preview {
	max-width: 200px;
}

@media print {
	html,
	body {
		margin: 0;
		padding: 0;
	}

	div .top-115 {
		margin-top: 0;
	}

	div .top-25 {
		margin-top: 0;
	}

	div .bottom-100 {
		margin-bottom: 0;
	}
}
@media screen and (min-width: 490px) {
	.nav-string {
		font-size: 2.5em;
	}
}

@media screen and (min-width: 190px) and (min-width: 990px) {
	.home__logo--header {
		margin-top: 131px;
	}
}

@media screen and (min-width: 990px) {
	.list {
		margin: 90px 0 33px;
	}
}

/* ============ desktop view ============ */
/* https://bootstrap-menu.com/detail-basic-hover.html */
/* Hover Menu without dropdown */
@media all and (min-width: 992px) {
	.nav-string {
		font-size: 1.3em;
	}

	.navbar__title {
		font-size: 1em;
	}

	.navbar .nav-item .dropdown-menu {
		display: none;
	}
	.navbar .nav-item:hover .dropdown-menu {
		display: block;
	}
	.navbar .nav-item .dropdown-menu {
		margin-top: 0;
	}
}
/* ============ desktop view .end// ============ */

@media all and (min-width: 1024px) {
	.nav-string {
		font-size: 2.2em;
	}

	.navbar__title {
		font-size: 1.5em;
	}
}

@media all and (min-width: 1200px) {
	.nav-string {
		font-size: 0.96rem;
	}

	.navbar__title {
		font-size: 1em;
	}

	.app-footer {
		padding-bottom: 1rem;
	}
}
