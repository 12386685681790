.h4__categories {
	padding-top: 5px;
	padding-bottom: 20px;
}

@media screen and (min-width: 360px) {
	.indent {
		margin-left: 80px;
	}

	.h4__categories {
		padding-top: 36px;
	}
}

@media screen and (min-width: 500px) {
	.indent {
		margin-left: 160px;
	}
}
